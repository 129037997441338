import { Grid } from '@material-ui/core'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "#fff",
        borderRadius: "2px 2px 2px 2px",
        boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.60)",
        cursor: "pointer"
    },
    iconGridItem: {
        width: 46,
        height: 46
    },
    textGridItem: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(2),
        color: "#757575",
        fontWeight: 600
    }
  }));

export default function GoogleLoginComponent() {
    let classes = useStyles();

    let handleClick = (e) => {
        window.location.href = "https://api.tabletop.cloud/account/login";
        console.log(e);
    }

    return (
        <Grid onClick={handleClick} className={classes.root} direction="row" justify="center" alignItems="center" spacing={0} container>
            <Grid className={classes.iconGridItem} item>
                <img alt="Google Logo" src="images/btn_google.svg"/>
            </Grid>
            <Grid className={classes.textGridItem} item>
                Sign in with Google
            </Grid>
        </Grid>
    )
}
