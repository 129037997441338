import React from 'react'
import { Paper, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        padding: "16px",
        margin: 0
    },
    grid: {
        //height: "100%"
    },
    icon: {
        textAlign: "center"
    },
    link: {
        color: theme.palette.text.primary
    }
  }));

export default function ProductCard(props) {
    let classes = useStyles();
    let {title, icon, url, issueTrackerUrl, roadMapUrl, changelogUrl} = props;

    return (
        <Paper className={classes.root}>
            <Grid direction="row" className={classes.grid} alignItems="center" spacing={2} container>
                <Grid item className={classes.icon} xs={4}>
                    {icon}
                </Grid>
                <Grid item className={classes.grid} xs={6}>
                    <Grid direction="column" justify="center" alignItems="flex-start" spacing={1} container>
                        <Grid item>
                            <a className={classes.link} href={url}><strong>{title}</strong></a>
                        </Grid>
                        <Grid item>
                            <a className={classes.link} href={issueTrackerUrl}>Issue tracker</a>
                        </Grid>
                        <Grid item>
                            <a className={classes.link} href={changelogUrl}>Changelog</a>
                        </Grid>
                        <Grid item>
                            <a className={classes.link} href={roadMapUrl}>Road map</a>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    )
}
