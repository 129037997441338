import React, { useState, useEffect } from "react";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import HomeButton from "../components/HomeButton";

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    padding: theme.spacing(4),
  },
}));

export default function SpellbookChangelog() {
  let classes = useStyles();
  const [state, setstate] = useState({ changelogs: {} });

  useEffect(() => {
    if (Object.keys(state.changelogs).length < 1) {
      fetch("https://api.tabletop.cloud/general/changelog?tool=spellbook", {})
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data.success === 0) {
            console.error(data);
          } else {
            setstate({ ...state, changelogs: data });
          }
        });
    }

    return;
  }, [state]);

  let changelog = [];
  for (const [key, value] of Object.entries(state.changelogs)) {
    //console.log(value);
    let features = [];
    let bugs = [];

    if (value.bug === undefined) {
      value.bug = [];
    }

    value.bug.forEach((element) => {
      bugs.push(<li>{element}</li>);
    });

    if (value.feature === undefined) {
      value.feature = [];
    }

    value.feature.forEach((element) => {
      features.push(<li>{element}</li>);
    });

    changelog.push(
      <div>
        <h2>
          Changes for {value.date} - v{key}
        </h2>
        <h3>Features:</h3>
        <ul>{features}</ul>
        <h3>Bugs</h3>
        <ul>{bugs}</ul>
      </div>
    );

    changelog.push(<hr class="dashed"></hr>);
  }

  changelog = changelog.reverse();

  return (
    <Paper className={classes.root}>
      <HomeButton />
      <h1>
        <strong>Changelog: Pathfinder spellbook</strong>
      </h1>
      {changelog}
      <p>
        Please leave any bug reports, feature requests and letters of worship on
        the issue tracker at gitlab:{" "}
        <a href="https://gitlab.com/tabletop-cloud/spellbook/-/issues">
          GitLab Issue tracker
        </a>
      </p>
    </Paper>
  );
}
