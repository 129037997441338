import React from 'react'
import { Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import HomeButton from '../components/HomeButton';

const useStyles = makeStyles(theme => ({
    root: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        padding: theme.spacing(2),
    }
  }));


export default function Contact() {
    let classes = useStyles();

    return (
        <Paper className={classes.root}>
            <HomeButton/>

            <h1><strong>Contact</strong></h1>
            <p>Please direct any questions or requests to&nbsp;<a href="mailto:webmaster@tabletop.cloud">webmaster@tabletop.cloud</a></p>
        </Paper>
    )
}
