export default {
    palette: {
      type: 'light',
      primary: {
        main: "#3498db",
        contrastText: "#fff"
      },
      secondary: {
        main: "#2980b9"
      },
      background:{
        default: "#ecf0f1",
        paper:"#FFF"
      },
      disabled:{
        main:"#AAA"
      },
      text:{
        primary: "#2c3e50"
      }
    },
    typography:{
    }
  };