import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import ProductCard from '../containers/ProductCard'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faFire } from '@fortawesome/free-solid-svg-icons'
import GoogleLogoutComponent from '../components/GoogleLogoutComponent';

import { isMobileOnly } from "react-device-detect";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
    menuContainer: {
        width: "100%"
    },
    loginContainer: {
        padding: theme.spacing(2),
    },
    cardItem: {
        width: "100%",
        maxWidth: (!isMobileOnly)?"350px":"100%"
    },
    welcomeText: {
        textAlign: "center",
        marginBottom: theme.spacing(2)
    }
  }));


export default function Home() {
    let classes = useStyles();
    
    let getCookieValue = (a) => {
        var b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)');
        return b ? b.pop() : '';
    }

    let userName = getCookieValue('tc_name');
    let token = getCookieValue('tc_token');

    let welcomeText;
    if (userName === '' && token === ''){
        welcomeText = 
        <Grid spacing={2} className={classes.welcomeText} direction="column" justify="center" alignItems="center" item container>
            <Grid item>Welcome to Tabletop Cloud! <br/>You are using our tools locally. <a href="/">Login?</a></Grid>
        </Grid>;
    } else {
        welcomeText = 
        <Grid spacing={2} className={classes.welcomeText} direction="column" justify="center" alignItems="center" item container>
            <Grid item>Welcome to Tabletop Cloud! <br/>You are logged in as&nbsp;<b>{userName}</b>.</Grid>
            <Grid item>
                <GoogleLogoutComponent/>
            </Grid>
        </Grid>;
    }

    return (
        <Grid className={classes.root} spacing={2} direction="column" justify="center" alignContent="center" alignItems="center" container>
            {welcomeText}
            <Grid className={classes.cardItem} item>
                <ProductCard
                title="Pathfinder spellbook"
                icon={<FontAwesomeIcon icon={faFire} size="3x" color="#833471"/>}
                url="https://spellbook.tabletop.cloud"
                issueTrackerUrl="https://gitlab.com/tabletop-cloud/spellbook/-/issues"
                roadMapUrl="/spellbook-roadmap"
                changelogUrl="/spellbook-changelog"
                />
            </Grid>
        </Grid>
    )
}
