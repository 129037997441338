import React from 'react';
import './styles/style.css';
import { Grid } from '@material-ui/core';
import Footer from './components/Footer';

import { makeStyles } from '@material-ui/core/styles';
import { Route, Switch } from 'react-router-dom';
import PrivacyPolicy from './Pages/PrivacyPolicyPage';
import TermsAndConditions from './Pages/TermsAndConditionsPage';
import Contact from './Pages/ContactPage';
import NotFound from './Pages/NotFoundPage';
import Home from './Pages/HomePage';
import SpellbookRoadmap from './Pages/SpellbookRoadmapPage';
import SpellbookChangelog from './Pages/SpellbookChangelogPage';
import RootPage from './Pages/RootPage';

import { isMobileOnly } from "react-device-detect";

const useStyles = makeStyles(theme => ({
    root: {
      margin: 0
    },
    text: {
      textAlign: "center"
    },
    paper: {
      width: "100%",
      maxWidth: (!isMobileOnly)?"800px":"100%",
      padding: "0px"
    },
    logo: {
      maxWidth: "80vw",
      minWidth: "20vw",
      fill: "#3498db",
      minHeight: "20vh"
    }
}));

function App(props) {
  const classes = useStyles();

  return (
    <div className={props.className}>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <img className={classes.logo} alt="logo, a cloud with a D20 inside of it" src="images/logo.png"></img>
          <h1>Tabletop Cloud</h1>
        </Grid>
        <Grid className={classes.paper} item>
          <Switch>
            <Route path="/" component={RootPage} exact />
            <Route path="/home" component={Home} exact />
            <Route path="/privacy" component={PrivacyPolicy} />
            <Route path="/terms" component={TermsAndConditions} />
            <Route path="/contact" component={Contact} />
            <Route path="/spellbook-roadmap" component={SpellbookRoadmap} />
            <Route path="/spellbook-changelog" component={SpellbookChangelog} />
            <Route path="*" component={NotFound} />
          </Switch>
        </Grid>
      </Grid>
      <Footer/>
    </div>
  );
}

export default App;
