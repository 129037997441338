import React, { useState }  from 'react'
import { Paper, Grid, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
import GoogleLoginComponent from '../components/GoogleLoginComponent';

const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.text.primary,
        width: "100%",
        textAlign: "center",
        padding: "16px"
    }
  }));

export default function LoginContainer() {
    const classes = useStyles();
    const [state, setState] = useState({
        isLoggedIn: false,
        userName: "John",
        userImage: ""
    })

    const useLocally = () => {
        setState({
            ...state,
            isLoggedIn: true
        });
    }
    
    return (
        <Paper className={classes.root}>
            {(!state.isLoggedIn)?
            <Grid spacing={2} direction="column" justify="center" alignItems="center" container>
                <Grid item>
                    Welcome to Tabletop Cloud! <br/><br/>Login to save your data on our tools accross devices or just use our tools locally it's up to you!
                </Grid>
                <Grid item>
                    <Button onClick={useLocally} variant="contained" color="primary">Use locally</Button>
                </Grid>
                <Grid item>
                    - Or -
                </Grid>
                <Grid item>
                    <GoogleLoginComponent/>
                </Grid>
            </Grid>
            :<Redirect to="/home"/>}
        </Paper>
    )
}
