import React from 'react'
import { Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        padding: theme.spacing(4),
        textAlign: "center"
    },
    gifContainer: {
        width: "100%",
        maxWidth: "50vh"
    },
  }));

export default function NotFound() {
    let classes = useStyles();

    return (
        <Paper className={classes.root}> 
            <h1><strong>Not Found</strong></h1>
            <p>Looks like you rolled a natural 1, this page does not exist. <a href="/home">Go back to the home page</a></p>
            <img className={classes.gifContainer} src="images/404gif.gif" alt="GIF Animation of putting a dice in the dice jail"></img>
        </Paper>
    )
}
