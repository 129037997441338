import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import LoginContainer from '../containers/LoginContainer';
import { Grid } from '@material-ui/core';
import { Redirect } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
    },
    menuContainer: {
        width: "100%"
    },
    loginContainer: {
        padding: theme.spacing(2),
    },
    cardItem: {
        width: "100%"
    }
  }));


export default function Home() {
    let classes = useStyles();

    let getCookieValue = (a) => {
        var b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)');
        return b ? b.pop() : '';
    }

    let userName = getCookieValue('tc_name');
    let token = getCookieValue('tc_token');

    let redirect;
    if (userName !== '' && token !== ''){
        redirect = <Redirect to="/home"/>;
    }

    return (
        <Grid className={classes.root} spacing={2} direction="column" container>
            <Grid item>
                <LoginContainer/>
                {redirect}
            </Grid>
        </Grid>
    )
}
