import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.text.primary,
        width: "100%",
        textAlign: "center",
        padding: theme.spacing(2)
    },
    link: {
        textDecoration: "none",
        color: theme.palette.text.primary
    }
  }));

export default function Footer() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <a className={classes.link} rel="noopener noreferrer" href="/">Tabletop Cloud</a>&nbsp;|&nbsp;
            <a className={classes.link} href="/privacy" rel="noopener noreferrer">Privacy Policy</a>&nbsp;|&nbsp;
            <a className={classes.link} href="/terms" rel="noopener noreferrer">Terms of service</a>&nbsp;|&nbsp;
            <a className={classes.link} href="/contact" rel="noopener noreferrer">Contact</a>
            <br/>
            Copyright &#169; {new Date().getFullYear()} Milan van Dijck
        </div>
    )
}
