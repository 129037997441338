import React from 'react'
import { Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import HomeButton from '../components/HomeButton';

const useStyles = makeStyles(theme => ({
    root: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        padding: theme.spacing(4),
    }
  }));

export default function SpellbookRoadmap() {
    let classes = useStyles();

    return (
        <Paper className={classes.root}>
            <HomeButton/>
            <h1><strong>Roadmap: Pathfinder spellbook</strong></h1>
            <p>We are currently working on the following features in this order:</p>
            <ul>
                <li>Improving readability of spells (tables in spell descriptions, linking to spells that say "this spell functions as") - ETA Oktober 2023</li>
                <li>Adding custom spells and viewing custom spells created by other people - ETA December 2023</li>
            </ul>
            <p>
            Please leave any bug reports and feature requests on the issue tracker at gitlab: <a href="https://gitlab.com/tabletop-cloud/spellbook/-/issues">GitLab Issue tracker</a>
            </p>
        </Paper>
    )
}
